import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { PromiseWithKnownReason } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { QueryFulfilledRejectionReason } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { authQuery } from '../utils/api';
import type { APICall, CustomerDashboard } from '../types';

interface CustomerQuery {
  customerId: string;
  proposalId: string;
}

interface CreatePaymentSessionMutationData {
  payment_method_types: Array<string>;
  proposal_id: string;
}

interface CustomerMutationData {
  customerId: string;
  quoteId: string;
}

interface FinancingStatusData extends CustomerMutationData {
  status: string;
}

interface InstallationDatesData extends CustomerMutationData {
  dates: Array<string>;
}

interface Proposal {
  address: string;
  deal_type: string;
  id: string;
  status: 'COMPLETED' | 'NOT_SCHEDULED' | 'SCHEDULED';
}

const numbers_as_strings = ['one', 'two', 'three'];

const updateCustomerTimelineOptimistically = async (
  customerId: string,
  proposalId: string,
  timelineKey: keyof CustomerDashboard['data']['timeline'],
  attributeKey: string,
  value: any,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  queryFulfilled: PromiseWithKnownReason<
    {
      data: APICall;
      meta: {} | undefined;
    },
    QueryFulfilledRejectionReason<
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>
    >
  >
) => {
  const patchResult = dispatch(
    customerDashboardApi.util.updateQueryData(
      'getCustomerDashboard',
      { customerId, proposalId },
      (draft) => {
        Object.assign(draft, {
          ...draft,
          data: {
            ...draft.data,
            timeline: {
              ...draft.data.timeline,
              [timelineKey]: {
                ...draft.data.timeline[timelineKey],
                [attributeKey]: value,
              },
            },
          },
        });
      }
    )
  );
  try {
    await queryFulfilled;
  } catch {
    patchResult.undo();
  }
};

export const customerDashboardApi = createApi({
  reducerPath: 'customerDashboardApi',
  baseQuery: authQuery,
  tagTypes: ['Customer'],
  endpoints: (builder) => ({
    getCustomerDashboard: builder.query<CustomerDashboard, CustomerQuery>({
      query: ({ customerId, proposalId }) => {
        return `customer/${customerId}/dashboard/${proposalId}`;
      },
      providesTags: ['Customer'],
    }),
    confirmInstallationComplete: builder.mutation<
      APICall,
      CustomerMutationData
    >({
      query: ({ quoteId }) => ({
        url: 'customer/installation/complete',
        method: 'POST',
        body: { quote_id: quoteId },
      }),
      invalidatesTags: ['Customer'],
      onQueryStarted: async (
        { customerId, quoteId },
        { dispatch, queryFulfilled }
      ) => {
        await updateCustomerTimelineOptimistically(
          customerId,
          quoteId,
          'confirmation',
          'status',
          'Install Complete',
          dispatch,
          queryFulfilled
        );
      },
    }),
    patchFinancingStatus: builder.mutation<APICall, FinancingStatusData>({
      query: ({ quoteId, status }) => {
        const body: { [index: string]: string } = { quote_id: quoteId, status };
        return {
          url: 'customer/financing/status',
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Customer'],
      onQueryStarted: async (
        { customerId, quoteId, status },
        { dispatch, queryFulfilled }
      ) => {
        await updateCustomerTimelineOptimistically(
          customerId,
          quoteId,
          'financing',
          'status',
          status,
          dispatch,
          queryFulfilled
        );
      },
    }),
    submitInstallationDates: builder.mutation<APICall, InstallationDatesData>({
      query: ({ dates, quoteId }) => {
        const body: { [index: string]: string } = { quote_id: quoteId };
        dates.reduce((acc, date, index) => {
          acc[`date_${numbers_as_strings[index]}`] = date;
          return acc;
        }, body);
        return {
          url: 'customer/installation/dates',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Customer'],
      onQueryStarted: async (
        { customerId, quoteId },
        { dispatch, queryFulfilled }
      ) => {
        await updateCustomerTimelineOptimistically(
          customerId,
          quoteId,
          'installation',
          'status',
          'Send Dates to CO',
          dispatch,
          queryFulfilled
        );
      },
    }),
    createPaymentSession: builder.mutation<
      { session_id: string; proposal_id: string },
      CreatePaymentSessionMutationData
    >({
      query: ({ payment_method_types, proposal_id }) => ({
        url: `customer/create_checkout_session`,
        method: 'POST',
        body: {
          payment_method_types,
          proposal_id,
        },
      }),
    }),
    futureLead: builder.mutation<
      APICall,
      {
        name?: string;
        address: string;
        email: string;
        phone?: string;
        fuel_assistance?: string;
        household_members?: string;
        household_income?: string;
        home_occupancy?: string;
        hea?: string;
        insulation_measure?: string;
      }
    >({
      query: ({
        name,
        address,
        email,
        phone,
        fuel_assistance,
        household_members,
        household_income,
        home_occupancy,
        hea,
        insulation_measure,
      }) => ({
        url: `customer/future_lead`,
        method: 'POST',
        body: {
          address,
          email,
          fuel_assistance: fuel_assistance ? fuel_assistance === 'yes' : null,
          hea: hea ? hea === 'yes' : null,
          household_income: household_income ? household_income : null,
          household_members: household_members ? household_members : null,
          insulation_measure: insulation_measure ? insulation_measure : null,
          name,
          phone,
          summer_home: home_occupancy ? home_occupancy === 'no' : null,
          page: window.location.pathname,
        },
      }),
    }),
    proposals: builder.query<{ results: Proposal[] }, void>({
      query: () => 'customer/proposals',
    }),
  }),
});

export const {
  useGetCustomerDashboardQuery,
  useConfirmInstallationCompleteMutation,
  usePatchFinancingStatusMutation,
  useSubmitInstallationDatesMutation,
  useCreatePaymentSessionMutation,
  useFutureLeadMutation,
  useProposalsQuery,
} = customerDashboardApi;
