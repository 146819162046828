import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { LeadQualification } from 'types/lead-qualification';

const initialState: LeadQualification = {
  utmData: {
    campaign: '',
    medium: '',
    source: '',
    content: '',
    term: '',
    gclid: '',
    _gl: '',
  },
  contactInfo: {
    name: '',
    email: '',
    phone: '',
  },
  address: '',
  addressData: {
    city: '',
    county: '',
    state: '',
    street_address: '',
    zip_code: '',
  },
  addressSelected: false,
  homeDataId: '',
  homeDetails: {
    area_in_sqft: '',
    basement_height: '',
    bath_count_total: '',
    cooling_system: '',
    fuel_type: '',
    fully_finished_basement: '',
    heating_system: '',
    home_type: '',
    year_built: '',
    zones: '',
  },
  customerGoals: {
    repair_hvac: false,
    upgrade_hvac: false,
    upgrade_water_heater: false,
    learn: false,
    add_ac: false,
    fix_parts_of_home: false,
    other: false,
    otherGoal: '',
  },
  system_type: '',
  hea: '',
  fuel_assistance: '',
  system: null,
  payment: '',
  rebate_qualified: true,
  insulation: '',
  home_occupancy: '',
  lead_source: '',
  referrer: '',
  number_floors: '',
  common_area_layout: '',
  basement_rooms: '',
  basement_layout: '',
  basement_height: '',
  first_floor_bedrooms: 0,
  first_floor_bathrooms: 0,
  other_first_floor_rooms: 0,
  second_floor_bedrooms: 0,
  second_floor_bathrooms: 0,
  other_second_floor_rooms: 0,
  third_floor_bedrooms: 0,
  third_floor_bathrooms: 0,
  other_third_floor_rooms: 0,
  goalsRanking: ['final_price', 'efficiency', 'wifi', 'sound_level'],
  last_page_visited: 'contact-info',
  customizations: [],
  hasCustomizations: false,
  waterHeaterCustomization: null,
  householdIncome: '',
  householdMembers: '',
};

export const leadQualification = createSlice({
  name: 'leadQualification',
  initialState,
  reducers: {
    updateByPayload: (state, action: PayloadAction<LeadQualification>) => {
      state = merge(state, action.payload);
    },
    updateCustomizations: (state, action: PayloadAction<LeadQualification>) => {
      return {
        ...state,
        customizations: action.payload.customizations,
      };
    },
  },
});

export const { updateByPayload, updateCustomizations } =
  leadQualification.actions;

export default leadQualification.reducer;
