import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { MAHouseholdMembers, NYHouseholdMembers } from 'types';
import { State } from 'types/general';
import { HEA, InsulationMeasure } from 'types/rebate-eligibility';
import { API_Endpoint } from '../utils/api';
import { BaseWithNotSureOption } from 'types/general';

export interface Rebates {
  heat_pump: string;
  heat_pump_water_heater: string;
  home_energy_assessment: string;
  insulation: string;
}

export interface TaxIncentives {
  heat_pump: string;
  electric_panel_upgrade: string;
  insulation: string;
}

export const rebateEligibilityApi = createApi({
  reducerPath: 'rebateEligibilityApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_Endpoint }),
  endpoints: (builder) => ({
    isServiceable: builder.query<
      {
        status: 'serviceable' | 'not_serviceable';
      },
      {
        address: string;
      }
    >({
      query: ({ address }) => ({
        url: `home/is_serviceable`,
        method: 'POST',
        body: { address },
      }),
    }),
    rebates: builder.query<
      {
        rebates: Rebates;
        tax_incentives: TaxIncentives;
        ny_rebate_program?: string;
      },
      {
        customer_email: string;
        hea: HEA;
        homeDataId: string;
        homeOccupancy: BaseWithNotSureOption;
        householdIncome: string;
        householdMembers: MAHouseholdMembers | NYHouseholdMembers;
        insulationMeasure: InsulationMeasure;
        state: State;
      }
    >({
      query: ({
        customer_email,
        hea,
        homeDataId,
        homeOccupancy,
        householdMembers,
        householdIncome,
        insulationMeasure,
        state,
      }) => ({
        url: `rebate/rebates`,
        method: 'POST',
        body: {
          customer_email,
          hea: hea === 'yes',
          insulation_measure: insulationMeasure,
          home_data_id: homeDataId,
          household_members:
            householdMembers === '' ? undefined : householdMembers,
          household_income:
            householdIncome === '' ? undefined : householdIncome,
          summer_home: homeOccupancy === 'no',
          state,
        },
      }),
    }),
  }),
});

export const { useIsServiceableQuery, useRebatesQuery } = rebateEligibilityApi;
