import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import {
  createMigrate,
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';

import { contractorApi } from 'endpoints/contractor';
import { customerDashboardApi } from 'endpoints/customer';
import { leadQualificationApi } from 'endpoints/lead-qualification';
import { mondayApi } from 'endpoints/monday';
import { rebateEligibilityApi } from 'endpoints/rebate-eligibility';
import { schedulerApi } from 'endpoints/scheduler';
import { userApi } from 'endpoints/user';
import auth from 'redux/features/auth';
import cards from 'redux/features/cards';
import completeJob from 'redux/features/complete-job';
import error from 'redux/features/error';
import general from 'redux/features/general';
import leadQualification from 'redux/features/lead-qualification';
import newOnboarding from 'redux/features/new-onboarding';
import onboarding from 'redux/features/onboarding';
import rebateEligibility from 'redux/features/rebate-eligibility';
import migrations from 'redux/migrations';

const authPersistConfig = {
  key: 'auth',
  version: 1,
  storage,
};

const persistConfig = {
  timeout: 500,
  key: 'root',
  version: 31,
  storage,
  blacklist: [
    contractorApi.reducerPath,
    customerDashboardApi.reducerPath,
    leadQualificationApi.reducerPath,
    mondayApi.reducerPath,
    rebateEligibilityApi.reducerPath,
    schedulerApi.reducerPath,
    userApi.reducerPath,
    'auth',
    'cards',
    'completeJob',
    'newOnboarding',
  ],
  migrate: createMigrate(migrations),
};

const reducersCombined = combineReducers({
  error,
  cards,
  completeJob,
  [contractorApi.reducerPath]: contractorApi.reducer,
  [customerDashboardApi.reducerPath]: customerDashboardApi.reducer,
  [leadQualificationApi.reducerPath]: leadQualificationApi.reducer,
  [mondayApi.reducerPath]: mondayApi.reducer,
  [rebateEligibilityApi.reducerPath]: rebateEligibilityApi.reducer,
  [schedulerApi.reducerPath]: schedulerApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  auth: persistReducer(authPersistConfig, auth),
  general,
  leadQualification,
  newOnboarding,
  onboarding,
  rebateEligibility,
});

const persistedReducer = persistReducer(persistConfig, reducersCombined);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      contractorApi.middleware,
      customerDashboardApi.middleware,
      leadQualificationApi.middleware,
      mondayApi.middleware,
      rebateEligibilityApi.middleware,
      schedulerApi.middleware,
      userApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
