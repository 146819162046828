/**
 * @param productTypeList array of product-types of active group.
 * @param allTypesOfProducts All products in all groups.
 * @returns array of products that belong to the active group
 */

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { SystemType } from 'types/lead-qualification';

dayjs.extend(utc);

const formatDatetime = (datetimeStr: string) => {
  const datetime = dayjs.utc(datetimeStr);
  if (datetime.startOf('day').isSame(datetime)) {
    return datetime.format('MMM DD, YYYY');
  }
  return dayjs(datetimeStr).format('MMM DD, YYYY - h:mma');
};

const VALID_MODEL_FAMILIES = [
  'sure-comfort',
  'high-efficiency',
  'combination',
  'evolution',
  'greenstar',
  'comfort',
  'legacy',
  'silver',
  'ncb',
  'nfc',
  'signature',
  'elite',
  'ecoer',
  'preferred',
];

const formatFileName = (str: string) =>
  str.toLowerCase().replaceAll('_', '-').replaceAll(' ', '-');

const buildSystemImageString = (
  manufacturer: string,
  system_type: SystemType,
  model_family?: string
) => {
  let productImageURL = `/images/systems/${formatFileName(
    manufacturer
  )}_${formatFileName(system_type)}`;
  if (
    model_family &&
    VALID_MODEL_FAMILIES.includes(formatFileName(model_family))
  ) {
    productImageURL += `_${formatFileName(model_family)}`;
  }
  productImageURL += '.png';

  return productImageURL;
};

export { buildSystemImageString, formatDatetime };
