import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  AddressData,
  Checkout,
  DMSDetails,
  FullContactInfo,
  Goal,
  HomeDetails,
  HomeDetailsResponse,
  SaveHomeResponse,
  SaveInfo,
  State,
  Customization,
  CustomizationsResponse,
  SystemRecommendationsResponse,
  ReviewResponse,
  SystemType,
  SystemTypeRecommendationsResponse,
  UTMData,
  RecommendationsResponse,
} from 'types/lead-qualification';
import { HomeDetails as RebateEligibilityHomeDetails } from 'types/rebate-eligibility';
import { API_Endpoint } from '../utils/api';
import { APICall } from 'types';

export const leadQualificationApi = createApi({
  reducerPath: 'leadQualificationApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_Endpoint }),
  tagTypes: ['getHomeDetails'],
  endpoints: (builder) => ({
    checkout: builder.mutation<SaveHomeResponse, Checkout>({
      query: ({
        contactInfo,
        systems,
        payment,
        homeDataId,
        payment_method_id,
        customizations,
      }) => ({
        url: 'ecomm/checkout',
        method: 'POST',
        body: {
          customizations,
          customer: contactInfo,
          systems: systems,
          financing: payment,
          home_data_id: homeDataId,
          payment_method_id: payment_method_id,
        },
      }),
    }),
    leadFormSubmit: builder.mutation<
      { existing_lead_id: String; redirect_url: string },
      {
        form_data: {
          fields: {
            full_name: string;
            address: string;
            email: string;
            phone_number: string;
            marketing_consent: string;
            hea?: string;
            home_occupancy?: string;
            insulation?: string;
            fuel_assistance?: string;
          };
          rebate_flow: boolean;
        };
      }
    >({
      query: (formDataInput) => {
        const formData = new FormData();
        (
          Object.keys(formDataInput.form_data.fields) as Array<
            keyof typeof formDataInput.form_data.fields
          >
        ).forEach((key) => {
          const value = formDataInput.form_data.fields[key];
          if (value) {
            formData.append(`fields[${key}]`, value);
          }
        });

        formData.append(
          'rebate_flow',
          String(formDataInput.form_data.rebate_flow)
        );

        return {
          url: 'ecomm/lead_form_submit',
          method: 'POST',
          body: formData,
        };
      },
    }),
    saveContactInfo: builder.mutation<
      HomeDetailsResponse,
      {
        contactInfo: FullContactInfo;
        utmData?: UTMData;
        lead_source?: String;
        referrer?: String;
        rebate_flow?: boolean;
      }
    >({
      query: ({
        contactInfo,
        utmData = {
          campaign: '',
          medium: '',
          source: '',
          content: '',
          term: '',
          gclid: '',
          _gl: '',
        },
        lead_source = '',
        referrer = '',
        rebate_flow = false,
      }) => ({
        url: 'ecomm/save_contact_info',
        method: 'POST',
        body: {
          ...contactInfo,
          utm_campaign: utmData.campaign,
          utm_medium: utmData.medium,
          utm_source: utmData.source,
          utm_content: utmData.content,
          utm_term: utmData.term,
          gclid: utmData.gclid,
          _gl: utmData._gl,
          lead_source: lead_source,
          referrer: referrer,
          rebate_flow: rebate_flow,
        },
      }),
    }),
    saveDMSDetails: builder.mutation<APICall, DMSDetails>({
      query: (dmsDetails) => ({
        url: `ecomm/${dmsDetails.home_data_id}/save_dms_details`,
        method: 'POST',
        body: dmsDetails,
      }),
    }),
    saveHome: builder.mutation<
      SaveHomeResponse,
      { addressData: AddressData; homeDetails: HomeDetails }
    >({
      query: ({ addressData, homeDetails }) => {
        const body: { [index: string]: any } = {
          ...addressData,
          ...homeDetails,
        };

        if (typeof homeDetails.bath_count_total === 'number') {
          body.bath_count = Math.floor(homeDetails.bath_count_total);
          body.bath_partial_count = (homeDetails.bath_count_total % 1) * 2;
        } else if (typeof homeDetails.bath_count_total === 'string') {
          body.bath_count = Math.floor(
            parseFloat(homeDetails.bath_count_total)
          );
          body.bath_partial_count =
            (parseFloat(homeDetails.bath_count_total) % 1) * 2;
        }

        return {
          url: 'ecomm/save_home_info',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['getHomeDetails'],
    }),
    saveInfo: builder.mutation<APICall, SaveInfo>({
      query: (saveInfo) => ({
        url: 'ecomm/save_info',
        method: 'POST',
        body: saveInfo,
      }),
    }),
    systemRecommendations: builder.query<
      SystemRecommendationsResponse,
      {
        homeDataId: string;
        systemType: SystemType;
        rebateQualified: boolean;
        goals: Goal[];
        enhancedRebate: boolean;
        state: State;
        underIncome: boolean;
      }
    >({
      query: ({
        homeDataId,
        systemType,
        rebateQualified,
        goals,
        enhancedRebate,
        state,
        underIncome,
      }) => {
        const queryParams = new URLSearchParams({
          home_data_id: homeDataId,
          system_type: systemType,
          rebate_qualified: rebateQualified.toString(),
          enhanced_rebate: enhancedRebate.toString(),
          state,
          under_income: underIncome?.toString(),
        });

        goals.forEach((goal) => {
          queryParams.append('priority_list', goal);
        });
        return `ecomm/system_recommendations?${queryParams}`;
      },
    }),
    review: builder.query<
      ReviewResponse,
      {
        homeDataId: string;
        customizations: Customization[];
        enhancedRebate: boolean;
        underIncome: boolean;
        systems: {
          id: string;
          system_type: string;
        }[];
      }
    >({
      query: ({
        homeDataId,
        customizations,
        enhancedRebate,
        systems,
        underIncome,
      }) => ({
        url: 'ecomm/review',
        method: 'POST',
        body: {
          customizations,
          home_data_id: homeDataId,
          systems,
          enhanced_rebate: enhancedRebate,
          under_income: underIncome,
        },
      }),
    }),
    systemTypeRecommendations: builder.query<
      SystemTypeRecommendationsResponse,
      {
        homeDataId: string;
        rebateQualified: boolean;
      }
    >({
      query: ({ homeDataId, rebateQualified }) => {
        const queryParams = new URLSearchParams({
          home_data_id: homeDataId,
          rebate_qualified: rebateQualified.toString(),
        });
        return `ecomm/system_type_recommendations?${queryParams}`;
      },
    }),
    customizations: builder.query<
      CustomizationsResponse,
      {
        homeDataId: string;
        systemType: SystemType;
        systemId: string;
        state: State;
        underIncome: boolean;
      }
    >({
      query: ({ homeDataId, systemType, systemId, state, underIncome }) => {
        const queryParams = new URLSearchParams({
          home_data_id: homeDataId,
          system_type: systemType,
          system_id: systemId,
          state,
          under_income: underIncome.toString(),
        });

        return `ecomm/customizations?${queryParams}`;
      },
    }),
    leadOwner: builder.query<
      {
        calendly_link: string;
        email: string;
        id: string;
        name: string;
        phone: string;
      },
      string
    >({
      query: (phone) => {
        const queryParams = new URLSearchParams({ phone });
        return `ecomm/lead_owner?${queryParams}`;
      },
    }),
    recommendations: builder.query<
      RecommendationsResponse,
      {
        homeDataId: string;
        rebateQualified: boolean;
        enhancedRebate: boolean;
        underIncome: boolean;
        goals: Goal[];
      }
    >({
      query: ({
        homeDataId,
        rebateQualified,
        enhancedRebate,
        underIncome,
        goals,
      }) => {
        const queryParams = new URLSearchParams({
          home_data_id: homeDataId,
          rebate_qualified: rebateQualified.toString(),
          enhanced_rebate: enhancedRebate.toString(),
          under_income: underIncome?.toString(),
        });

        goals.forEach((goal) => {
          queryParams.append('priority_list', goal);
        });

        return `ecomm/recommendations?${queryParams}`;
      },
    }),
    getHomeDetails: builder.query<
      {
        data: {
          home_data: RebateEligibilityHomeDetails;
          postal_address: {
            city: string;
            state: string;
            street_address: string;
            zip_code: string;
          };
        };
      },
      {
        address: string;
      }
    >({
      query: ({ address }) => ({
        url: `home/ecomm_lookup`,
        method: 'POST',
        body: { address },
      }),
      providesTags: ['getHomeDetails'],
    }),
  }),
});

export const {
  useCheckoutMutation,
  useLeadFormSubmitMutation,
  useSaveContactInfoMutation,
  useSaveDMSDetailsMutation,
  useSaveHomeMutation,
  useSaveInfoMutation,
  useSystemRecommendationsQuery,
  useReviewQuery,
  useLazySystemTypeRecommendationsQuery,
  useCustomizationsQuery,
  useLazyCustomizationsQuery,
  useLeadOwnerQuery,
  useRecommendationsQuery,
  useGetHomeDetailsQuery,
} = leadQualificationApi;
